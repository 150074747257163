import React, { useState } from "react";
import {
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import DraftsIcon from "@mui/icons-material/Drafts";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import axiosAuth from "../../../../api/axiosConfig";
import { endpoint } from "../../../../endpoint";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import ConfirmDelete from "../components/ConfirmDelete";
import CachedIcon from "@mui/icons-material/Cached";

const EmailsPanel = ({
  emails,
  setEmails,
  isXs,
  isSm,
  setSnackbarMessage,
  setSnackbarOpen,
  fetchEmails,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);
  const [isRotating, setIsRotating] = useState(false);
  const itemsPerPage = 5;
  const indexOfLastEmail = currentPage * itemsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - itemsPerPage;
  const currentEmails = emails.slice(indexOfFirstEmail, indexOfLastEmail);
  const pageCount = Math.ceil(emails.length / itemsPerPage);

  const handleOpenEmail = async (email) => {
    try {
      await axiosAuth.put(`${endpoint.baseUrl}/emails/${email.id}`, {
        is_read: true,
      });
      const updatedEmails = emails.map((e) =>
        e.id === email.id ? { ...e, is_read: true } : e
      );
      setEmails(updatedEmails);
      setSelectedEmail(email);
      setOpen(true);
    } catch (error) {
      console.error("Failed to mark email as read", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAsRead = async (email) => {
    try {
      await axiosAuth.put(`${endpoint.baseUrl}/emails/${email.id}`, {
        is_read: false,
      });
      const updatedEmails = emails.map((e) =>
        e.id === email.id ? { ...e, is_read: false } : e
      );
      setEmails(updatedEmails);
    } catch (error) {
      console.error("Failed to mark email as unread", error);
    }
  };

  const handleDeleteEmails = async (email) => {
    try {
      await axiosAuth.delete(`${endpoint.baseUrl}/emails/${email.id}`);
      setEmails(emails.filter((e) => e.id !== email.id));
      setSnackbarMessage({
        text: "Email został usunięty.",
        severity: "success",
      });
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to delete email", error);
    }
    setConfirmOpen(false);
  };

  const handleConfirmDelete = (email) => {
    setEmailToDelete(email);
    setConfirmOpen(true);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setSnackbarMessage({
          text: "Skopiowano adres email",
          severity: "success",
        });
        setSnackbarOpen(true);
      },
      () => {
        setSnackbarMessage({
          text: "Nie udało się skopiować adresu email",
          severity: "error",
        });
        setSnackbarOpen(true);
      }
    );
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRefreshEmails = () => {
    setIsRotating(true);
    fetchEmails();
    setTimeout(() => setIsRotating(false), 600);
  };

  return (
    <>
      <InfoAdmin text="Wiadomości od klientów" />
      <Paper
        sx={{
          padding: "45px 32px 32px 32px",
          marginTop: 2,
          width: isXs || isSm ? "100%" : 600,
          position: "relative",
        }}
      >
        <IconButton
          onClick={handleRefreshEmails}
          style={{
            height: "fit-content",
            alignSelf: "center",
            position: "absolute",
            top: 16,
            right: 16,
            transition: "transform 0.6s",
            transform: isRotating ? "rotate(360deg)" : "rotate(0deg)",
          }}
          color="primary"
        >
          <CachedIcon />
        </IconButton>

        {currentEmails.length > 0 ? (
          <List>
            {currentEmails.map((email) => (
              <React.Fragment key={email.id}>
                <div style={{ display: "flex" }}>
                  <ListItemButton onClick={() => handleOpenEmail(email)}>
                    {email.is_read ? (
                      <DraftsIcon color="action" />
                    ) : (
                      <MarkunreadIcon sx={{ color: "#227722" }} />
                    )}
                    <Divider
                      orientation="vertical"
                      style={{ height: "75%", margin: 20 }}
                    />
                    <ListItemText
                      primary={email.title}
                      primaryTypographyProps={{ sx: { fontWeight: 600 } }}
                      secondary={`${email.name}, ${email.email}`}
                    />
                  </ListItemButton>
                  <IconButton
                    onClick={() => handleMarkAsRead(email)}
                    style={{
                      padding: 10,
                      height: "fit-content",
                      alignSelf: "center",
                      color: email.is_read ? "#000" : "",
                    }}
                    disabled={!email.is_read}
                  >
                    <MarkAsUnreadIcon />
                  </IconButton>
                  <Divider
                    orientation="vertical"
                    style={{ height: "auto", margin: 15 }}
                  />
                  <IconButton
                    onClick={() => handleConfirmDelete(email)}
                    style={{
                      padding: 5,
                      height: "fit-content",
                      alignSelf: "center",
                      color: "#ad1f1f",
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography sx={{ textAlign: "center", marginTop: 2, color: "gray" }}>
            Brak wiadomości
          </Typography>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {Array.from({ length: pageCount }, (_, i) => (
            <Button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              sx={{
                margin: "0 5px",
                color: "#000",
                fontWeight: 600,
                textDecoration: currentPage === i + 1 ? "underline" : "none",
              }}
            >
              {i + 1}
            </Button>
          ))}
        </div>
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            fontWeight: 600,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 50px",
            paddingLeft: 3,
          }}
        >
          {selectedEmail?.title}
          <IconButton
            aria-label="copy"
            onClick={() => copyToClipboard(selectedEmail?.email)}
            sx={{ color: "#000", marginRight: "auto" }}
          >
            <ContentCopyIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "#000" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Od: {selectedEmail?.name} ({selectedEmail?.email})
          </DialogContentText>
          <Divider sx={{ marginTop: 4 }} />
          <DialogContentText
            sx={{ my: 1, color: "#000", width: "100%", textWrap: "wrap" }}
            component="pre"
          >
            {selectedEmail?.text}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ConfirmDelete
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => handleDeleteEmails(emailToDelete)}
        title="Czy na pewno chcesz usunąć tego emaila?"
      />
    </>
  );
};

export default EmailsPanel;
