import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import useIsMobile from "../../Assets/useIsMobile";
import BoxHomePage from "../../Assets/BoxHomePage";
import axios from "axios";
import { endpoint } from "../../endpoint";
import HomePageStyles from "./HomePageStyles";
import { SpinnerDotted } from "spinners-react";
import { useSpring, animated } from "@react-spring/web";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const HomePage = () => {
  const { isXs, isSm } = useIsMobile();
  const classes = HomePageStyles();
  const [boxData, setBoxData] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const fadeStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: loading || imageLoading ? 0 : 1 },
    config: { duration: 400 },
  });

  const slideUp = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform: loading || imageLoading ? "translateY(-50%)" : "translateY(0px)",
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const slideFromLeft = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform:
      loading || imageLoading ? "translateX(-100%)" : "translateX(0px)",
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  const slideFromRight = useSpring({
    opacity: loading || imageLoading ? 0 : 1,
    transform: loading || imageLoading ? "translateX(100%)" : "translateX(0px)",
    config: { tension: 130, friction: 30 },
    delay: 200,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const boxResponse = axios.get(`${endpoint.baseUrl}/homePageBox`);
        const imagesResponse = axios.get(`${endpoint.baseUrl}/homePageImages`);

        const [boxResult, imagesResult] = await Promise.all([
          boxResponse,
          imagesResponse,
        ]);

        setBoxData(boxResult.data);

        const formattedImages = imagesResult.data.map((img) => ({
          original: `${endpoint.baseUrl}/${img.imagePath.replace(/\\/g, "/")}`,
          id: img.id,
        }));
        setImages(formattedImages);
      } catch (error) {
        console.error("There was an error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const handleFullScreen = (index) => {
    setTimeout(() => {
      setPhotoIndex(index);
      setIsOpen(true);
    }, 200);
  };

  const renderCustomItem = (item) => {
    const currentIndex = images.findIndex(
      (img) => img.original === item.original
    );
    return (
      <div style={{ position: "relative" }}>
        <img
          src={item.original}
          alt={item.originalAlt}
          style={{
            width: "100%",
            height: "auto",
            opacity: imageLoading ? 0 : 1,
          }}
          onLoad={handleImageLoad}
        />
        <IconButton
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
          }}
          onClick={() => handleFullScreen(currentIndex)}
        >
          <FullscreenIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <>
      {(loading || imageLoading) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <SpinnerDotted size={90} color="#a9ffff" />
        </div>
      )}
      <animated.div
        style={{ ...fadeStyle, display: loading || imageLoading ? "none" : "" }}
        className={isSm || isXs ? classes.bodyMobile : classes.body}
      >
        <>
          <animated.div
            style={slideUp}
            className={isSm || isXs ? classes.galleryMobile : classes.gallery}
          >
            <ImageGallery
              items={images}
              autoPlay={true}
              slideInterval={6000}
              showFullscreenButton={false}
              useBrowserFullscreen={false}
              renderItem={(item) => renderCustomItem(item)}
            />
            {isOpen && (
              <Lightbox
                slides={images.map((image) => ({ src: image.original }))}
                open={isOpen}
                close={() => setIsOpen(false)}
                currentIndex={photoIndex}
                onClose={() => setIsOpen(false)}
                onPrev={() =>
                  setPhotoIndex(
                    (photoIndex + images.length - 1) % images.length
                  )
                }
                onNext={() => setPhotoIndex((photoIndex + 1) % images.length)}
              />
            )}
          </animated.div>
          <Grid container spacing={3}>
            {boxData.map((content, index) => {
              return (
                <Grid item xs={isXs || isSm ? 12 : 6} key={index}>
                  <animated.div
                    style={index % 2 === 0 ? slideFromLeft : slideFromRight}
                  >
                    <BoxHomePage
                      title={content.title}
                      description={content.description}
                    />
                  </animated.div>
                </Grid>
              );
            })}
          </Grid>
        </>
      </animated.div>
    </>
  );
};

export default HomePage;
