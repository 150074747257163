import React, { useState, useEffect } from "react";
import axios, { CancelToken } from "axios";
import {
  Container,
  Box,
  Button,
  TextField,
  IconButton,
  Paper,
  Modal,
  Typography,
  LinearProgress,
  Snackbar,
  Alert,
  MenuItem,
  Menu,
  ListItemText,
  ListItem,
  List,
  Divider,
  CircularProgress,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import LinkIcon from "@mui/icons-material/Link";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { endpoint } from "../../../../endpoint";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import axiosAuth from "../../../../api/axiosConfig";
import ConfirmDelete from "../components/ConfirmDelete";
import useIsMobile from "../../../../Assets/useIsMobile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import CachedIcon from "@mui/icons-material/Cached";
import { SpinnerDotted } from "spinners-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 300,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const formatFileSize = (size) => {
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
};

const FilePageAdmin = () => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [confirmVisibilityOpen, setConfirmVisibilityOpen] = useState(false);
  const [fileToToggleVisibility, setFileToToggleVisibility] = useState(null);
  const { isXs, isSm } = useIsMobile();
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRotating, setIsRotating] = useState(false);
  const actionMenuOpen = Boolean(actionAnchorEl);
  const [cancelUploadOpen, setCancelUploadOpen] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [downloading, setDownloading] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionMenuClick = (event, file) => {
    setActionAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleActionMenuClose = () => {
    setActionAnchorEl(null);
    setSelectedFile(null);
  };

  useEffect(() => {
    fetchFiles();
    fetchUsedSpace();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${endpoint.baseUrl}/files/all`);
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsedSpace = async () => {
    try {
      const response = await axiosAuth.get(
        `${endpoint.baseUrl}/files/used-space`
      );
      setTotalSize(response.data.totalSize);
    } catch (error) {
      console.error("Error fetching used space", error);
      setTotalSize(0);
    }
  };
  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (description.length > 50) {
      setSnackbarMessage("Opis nie może mieć więcej niż 50 znaków");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", description);
    formData.append("show", show);

    const source = CancelToken.source();
    setCancelTokenSource(source);

    try {
      await axiosAuth.post(`${endpoint.baseUrl}/files/upload`, formData, {
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      fetchFiles();
      fetchUsedSpace();
      setFile(null);
      setDescription("");
      setShow(false);
      setUploadProgress(0);
      setSnackbarMessage("Przesłano plik pomyślnie");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      if (axios.isCancel(error)) {
        setSnackbarMessage("Przesyłanie pliku zostało anulowane");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Błąd podczas przesyłania pliki");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosAuth.delete(`${endpoint.baseUrl}/files/${id}`);
      fetchFiles();
      fetchUsedSpace();
      setSnackbarMessage("Plik usunięto pomyślnie");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Błąd podczas usuwania pliku");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleGenerateLink = async (id) => {
    try {
      const response = await axiosAuth.post(
        `${endpoint.baseUrl}/files/generate/${id}`
      );
      setGeneratedLink(response.data.link);
      setModalOpen(true);
      setSnackbarMessage("Link został wygenerowany");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 410) {
        setSnackbarMessage("Nie znaleziono pliku do wygenerowania");
      } else {
        setSnackbarMessage("Błąd podczas generowania linku");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleToggleVisibility = (file) => {
    setFileToToggleVisibility(file);
    setConfirmVisibilityOpen(true);
  };

  const confirmToggleVisibility = async () => {
    try {
      const { id, show } = fileToToggleVisibility;
      await axiosAuth.put(`${endpoint.baseUrl}/files/update-show/${id}`, {
        show: !show,
      });
      fetchFiles();
      setSnackbarMessage(`Plik jest ${!show ? "widoczny" : "ukryty"}`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setConfirmVisibilityOpen(false);
    } catch (error) {
      setSnackbarMessage("Błąd podcas miany statusu");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setConfirmVisibilityOpen(false);
    }
  };

  const handleConfirmDelete = (file) => {
    setFileToDelete(file);
    setConfirmDeleteOpen(true);
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      handleDelete(fileToDelete.id);
      setFileToDelete(null);
      setConfirmDeleteOpen(false);
    }
  };

  const confirmCancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Upload cancelled by the user.");
    }
    setUploadProgress(0);
    setCancelUploadOpen(false);
    setFile(null);
    setCancelTokenSource(null);
    setSnackbarMessage("Przesyłanie pliku zostało anulowane");
    setSnackbarSeverity("warning");
    setSnackbarOpen(true);
  };

  const handleDownload = async (id) => {
    setDownloading(id);
    try {
      setSnackbarMessage("Pobieranie pliku...");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);

      const response = await axiosAuth.get(
        `${endpoint.baseUrl}/files/download-file/${id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        files.find((file) => file.id === id).filename
      );
      document.body.appendChild(link);
      link.click();
      setSnackbarMessage("Plik został pobrany pomyślnie");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Błąd podczas pobierania pliku");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setDownloading(null);
    }
  };

  const clearFile = () => {
    setFile(null);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarMessage("Link został skopiowany");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    });
  };

  const handleRefreshFiles = () => {
    setIsRotating(true);
    fetchFiles();
    fetchUsedSpace();
    setTimeout(() => setIsRotating(false), 600);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SpinnerDotted size={90} color="#a9ffff" />
      </div>
    );
  }

  return (
    <>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: isXs || isSm ? "20px 0px" : "",
        }}
      >
        <Paper
          my={4}
          sx={{
            padding: isXs ? "10px 16px" : "10px 30px",
            maxWidth: 700,
            width: "100%",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleRefreshFiles}
            style={{
              height: "fit-content",
              alignSelf: "center",
              position: "absolute",
              top: 16,
              right: 16,
              transition: "transform 0.6s",
              transform: isRotating ? "rotate(360deg)" : "rotate(0deg)",
            }}
            color="primary"
          >
            <CachedIcon />
          </IconButton>
          <InfoAdmin text="Pliki" blackText />
          <Box
            sx={{
              width: "100%",
              my: 2,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Wykorzystano {formatFileSize(totalSize || 0)} z 200 GB
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(totalSize / (200 * 1024 * 1024 * 1024)) * 100}
            />
            <Typography variant="body2" color="textSecondary">
              Pozostało {formatFileSize(200 * 1024 * 1024 * 1024 - totalSize)}
            </Typography>
          </Box>
          <List>
            {files
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((file) => (
                <ListItem
                  key={file.id}
                  sx={{
                    borderBottom: "1px solid #878787",
                    pr: isXs ? 0 : "",
                    pl: isXs ? 0 : "",
                  }}
                >
                  <ListItemText
                    primary={file.filename}
                    secondary={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                        }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            color: "#494949",
                            wordBreak: "break-word",
                            paddingRight: 5,
                          }}
                        >
                          {file.description}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            color: "#434343",
                            fontSize: 12,
                          }}
                        >
                          {formatFileSize(file.size)}
                        </Typography>
                      </div>
                    }
                    primaryTypographyProps={{
                      style: { wordBreak: "break-word", fontWeight: 600 },
                    }}
                    secondaryTypographyProps={{
                      style: {
                        color: "#afafaf",
                        wordBreak: "break-word",
                        paddingRight: 5,
                      },
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      gap: isXs ? 5 : 30,
                      alignItems: "center",
                    }}
                  >
                    {isXs || isSm ? (
                      <>
                        <IconButton
                          color={file.show ? "primary" : "default"}
                          onClick={() => handleToggleVisibility(file)}
                        >
                          {file.show ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          style={{ height: 40, borderColor: "#878787" }}
                        />
                        <IconButton
                          color="primary"
                          onClick={(event) =>
                            handleActionMenuClick(event, file)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={actionAnchorEl}
                          open={actionMenuOpen && selectedFile === file}
                          onClose={handleActionMenuClose}
                        >
                          <MenuItem onClick={() => handleGenerateLink(file.id)}>
                            <LinkIcon
                              style={{ marginRight: 8 }}
                              color="primary"
                            />
                            Wygeneruj link
                          </MenuItem>
                          <MenuItem onClick={() => handleConfirmDelete(file)}>
                            <DeleteForeverIcon
                              style={{ marginRight: 8, color: "#ad1f1f" }}
                            />
                            Usuń
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDownload(file.id)}
                            disabled={downloading}
                          >
                            {downloading === file.id ? (
                              <CircularProgress
                                size={24}
                                style={{ marginRight: 8 }}
                              />
                            ) : (
                              <DownloadIcon
                                style={{ marginRight: 8 }}
                                color="primary"
                              />
                            )}
                            Pobierz
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex", gap: 20 }}>
                          <Divider
                            orientation="vertical"
                            style={{ height: 40, borderColor: "#878787" }}
                          />
                          <IconButton
                            color={file.show ? "primary" : "default"}
                            onClick={() => handleToggleVisibility(file)}
                          >
                            {file.show ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                          <Divider
                            orientation="vertical"
                            style={{ height: 40, borderColor: "#878787" }}
                          />
                          <IconButton
                            color="primary"
                            onClick={() => handleGenerateLink(file.id)}
                          >
                            <LinkIcon />
                          </IconButton>

                          <IconButton
                            color="secondary"
                            onClick={() => handleConfirmDelete(file)}
                            sx={{ color: "#ad1f1f" }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>

                          <IconButton
                            color="primary"
                            onClick={() => handleDownload(file.id)}
                            disabled={downloading}
                          >
                            {downloading === file.id ? (
                              <CircularProgress size={24} />
                            ) : (
                              <DownloadIcon />
                            )}
                          </IconButton>
                          <Divider
                            orientation="vertical"
                            style={{ height: 40, borderColor: "#878787" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </ListItem>
              ))}
          </List>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={files.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {uploadProgress > 0 && (
            <Box
              sx={{
                width: "100%",
                my: 2,
                position: "relative",
              }}
            >
              <LinearProgress variant="determinate" value={uploadProgress} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >{`${uploadProgress}%`}</Typography>
                <IconButton
                  color="error"
                  onClick={() => setCancelUploadOpen(true)}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </Box>
          )}

          <div style={{ display: "flex", gap: 30, marginTop: 30 }}>
            <TextField
              label="Opis"
              value={description}
              onChange={(e) =>
                setDescription(
                  e.target.value.length <= 50
                    ? e.target.value
                    : e.target.value.substring(0, 50)
                )
              }
              fullWidth
              multiline
              size="small"
              margin="normal"
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {!file && (
                <>
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <label htmlFor="file-input">
                    <IconButton component="span" color="primary">
                      <CloudUploadIcon style={{ fontSize: 35 }} />
                    </IconButton>
                  </label>
                </>
              )}
              {file && (
                <div style={{ display: "table-column" }}>
                  <Button
                    type="submit"
                    onClick={handleFileUpload}
                    variant="contained"
                    disabled={!file}
                    sx={{
                      my: 2,
                      color: "#fff",
                      background:
                        "linear-gradient(180deg, #121212,  #6d6d6de6 )",
                      fontWeight: 800,
                    }}
                  >
                    Prześlij
                  </Button>
                </div>
              )}
            </Box>
          </div>
          {file && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body2">{file.name}</Typography>
              {uploadProgress === 0 && (
                <IconButton
                  onClick={clearFile}
                  style={{ color: "#ad1f1f" }}
                  size="small"
                >
                  <BackspaceIcon />
                </IconButton>
              )}
            </Box>
          )}
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Box sx={style}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" component="h2">
                  Wygenerowany link
                </Typography>
                <IconButton onClick={() => copyToClipboard(generatedLink)}>
                  <ContentCopyIcon />
                </IconButton>
              </div>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ mr: 2 }}>
                  <a
                    href={generatedLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: "100%", wordBreak: "break-word" }}
                  >
                    {generatedLink}
                  </a>
                </Typography>
              </Box>
            </Box>
          </Modal>
          <ConfirmDelete
            open={cancelUploadOpen}
            onClose={() => setCancelUploadOpen(false)}
            onConfirm={confirmCancelUpload}
            title={"Czy na pewno chcesz anulować przesyłanie pliku?"}
          />
          <ConfirmDelete
            open={confirmDeleteOpen}
            onClose={() => setConfirmDeleteOpen(false)}
            onConfirm={confirmDeleteFile}
            title={`Czy napewno chcesz usunąć ten plik "${fileToDelete?.filename}"?`}
          />
          <ConfirmDelete
            open={confirmVisibilityOpen}
            onClose={() => setConfirmVisibilityOpen(false)}
            onConfirm={confirmToggleVisibility}
            title={`Czy napewno chcesz ${
              fileToToggleVisibility?.show ? "ukryć" : "pokazać"
            } ten plik "${fileToToggleVisibility?.filename}"?`}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Paper>
      </Container>
    </>
  );
};

export default FilePageAdmin;
