import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Paper,
  Divider,
  InputAdornment,
  Snackbar,
  Alert,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import useIsMobile from "../../../../Assets/useIsMobile";
import InfoAdmin from "../../../../Assets/InfoAdmin";
import BackspaceIcon from "@mui/icons-material/Backspace";
import axios from "axios";
import { endpoint } from "../../../../endpoint";
import axiosAuth from "../../../../api/axiosConfig";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmDelete from "../components/ConfirmDelete";
import { SpinnerDotted } from "spinners-react";
import { makeStyles } from "@mui/styles";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  imageRow: {
    display: "flex",
    overflowX: "auto",
    paddingBottom: 2,
  },
  imageCard: {
    flex: "0 0 auto",
    margin: 2,
    position: "relative",
    width: "auto !important",
    height: "180px !important",
    minWidth: 150,
  },
  media: {
    width: "auto !important",
    height: "180px !important",
  },
  inputFile: {
    display: "none",
  },
  uploadButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },
  modalImage: {
    width: "auto",
    height: "auto",
    maxHeight: "90vh",
    maxWidth: "90vh",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    textAlign: "center",
  },
}));

const OfferPageAdmin = () => {
  const [offers, setOffers] = useState([]);
  const [hasChanged, setHasChanged] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    text: "",
    severity: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState(null);
  const { isXs, isSm } = useIsMobile();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(`${endpoint.baseUrl}/offerPages`);
        const offersWithAllImages = response.data.map((offer) => ({
          ...offer,
          allImages: [
            ...offer.images.map((image) => ({
              url: `${endpoint.baseUrl}/${image.replace(/\\/g, "/")}`,
            })),
            ...(offer.previewImages || []).map((image) => ({
              url: image,
            })),
          ],
        }));
        setOffers([...offersWithAllImages, createEmptyOffer()]);
        setHasChanged([...response.data.map(() => false), false]);
      } catch (error) {
        console.error("There was an error fetching the offers: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOffers();
  }, []);

  const createEmptyOffer = () => ({
    id: null,
    label: "",
    images: [],
    imageFiles: [],
    allImages: [],
    removeImages: false,
    sections: [""],
  });

  const handleImageChange = (event, index) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      const newOffers = [...offers];

      if (!newOffers[index].allImages) {
        newOffers[index].allImages = [];
      }

      files.forEach((file) => {
        const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
        let newFileName = fileNameWithoutExtension;
        let counter = 1;

        const existingNames = offers.flatMap((offer) =>
          offer.allImages.map((image) => {
            const imageName = image.url
              .split("/")
              .pop()
              .replace(/\.[^/.]+$/, "");
            return imageName;
          })
        );

        while (existingNames.includes(newFileName)) {
          newFileName = `${fileNameWithoutExtension} (${counter})`;
          counter++;
        }

        const fileWithNewName = new File(
          [file],
          `${newFileName}${file.name.slice(file.name.lastIndexOf("."))}`
        );

        newOffers[index].allImages.unshift({
          url: URL.createObjectURL(fileWithNewName),
          file: fileWithNewName,
        });
      });

      setOffers(newOffers);
      updateHasChanged(index, true);
    }
  };

  const handleDeleteImage = (index, imageIndex) => {
    const newOffers = [...offers];
    newOffers[index].allImages.splice(imageIndex, 1);
    setOffers(newOffers);
    updateHasChanged(index, true);
  };

  const handleTextChange = (event, index, sectionIndex) => {
    const newOffers = [...offers];
    if (typeof sectionIndex === "number") {
      newOffers[index].sections[sectionIndex] = event.target.value;
    } else {
      newOffers[index][sectionIndex] = event.target.value;
    }
    setOffers(newOffers);
    updateHasChanged(index, true);
  };

  const handleAddSection = (index) => {
    const newOffers = [...offers];
    if (!newOffers[index].sections) {
      newOffers[index].sections = [];
    }
    newOffers[index].sections.push("");
    setOffers(newOffers);
    updateHasChanged(index, true);
  };

  const handleDeleteSection = (offerIndex, sectionIndex) => {
    const newOffers = [...offers];
    newOffers[offerIndex].sections.splice(sectionIndex, 1);
    setOffers(newOffers);
    updateHasChanged(offerIndex, true);
  };

  const moveImage = (offerIndex, imageIndex, direction) => {
    const offer = offers[offerIndex];
    const allImages = offer.allImages || [];
    const newIndex = imageIndex + direction;

    if (newIndex < 0 || newIndex >= allImages.length) return;

    const updatedImages = [...allImages];
    const [movedImage] = updatedImages.splice(imageIndex, 1);
    updatedImages.splice(newIndex, 0, movedImage);

    const newOffers = [...offers];
    newOffers[offerIndex].allImages = updatedImages;
    setOffers(newOffers);
    updateHasChanged(offerIndex, true);
  };

  const handleConfirmDeleteOffer = async () => {
    setDialogOpen(false);
    if (selectedOfferId !== null) {
      try {
        await axiosAuth.delete(
          `${endpoint.baseUrl}/offerPages/${selectedOfferId}`
        );
        const newOffers = offers.filter(
          (offer) => offer.id !== selectedOfferId
        );
        setOffers([...newOffers]);
        setHasChanged(newOffers.map(() => false));
        setSnackbarMessage({
          text: `Oferta #${selectedOfferIndex + 1} została usunięta.`,
          severity: "success",
        });
        setSnackbarOpen(true);
      } catch (error) {
        console.error("There was an error deleting the offer: ", error);
        setSnackbarMessage({
          text: "Błąd podczas usuwania oferty.",
          severity: "error",
        });
        setSnackbarOpen(true);
      }
    }
  };

  const handleDeleteOffer = (offerId, index) => {
    setSelectedOfferId(offerId);
    setSelectedOfferIndex(index);
    setDialogOpen(true);
  };

  const handleSaveOffer = async (offerIndex) => {
    const offer = offers[offerIndex];

    if (!offer.label || offer.sections.some((section) => !section)) {
      setSnackbarMessage({
        text: "Proszę wypełnić wszystkie pola.",
        severity: "warning",
      });
      setSnackbarOpen(true);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("label", offer.label);

      const existingImages = [];
      (offer.allImages || []).forEach((image) => {
        if (image.file) {
          formData.append("images", image.file);
        } else {
          existingImages.push(image.url.replace(`${endpoint.baseUrl}/`, ""));
        }
      });
      formData.append("existingImages", JSON.stringify(existingImages));

      offer.sections.forEach((section, index) => {
        formData.append(`sections[${index}]`, section);
      });

      const response = await axiosAuth.put(
        `${endpoint.baseUrl}/offerPages/${offer.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedOffers = [...offers];
      updatedOffers[offerIndex] = {
        ...response.data,
        allImages: [
          ...response.data.images.map((image) => ({
            url: `${endpoint.baseUrl}/${image.replace(/\\/g, "/")}`,
          })),
        ],
      };
      updateHasChanged(offerIndex, false);
      setOffers(updatedOffers);
      setSnackbarMessage({
        text: "Oferta została zapisana.",
        severity: "success",
      });
      setSnackbarOpen(true);
    } catch (error) {
      console.error("There was an error saving the offer: ", error);
      setSnackbarMessage({
        text: "Błąd podczas zapisywania oferty.",
        severity: "error",
      });
      setSnackbarOpen(true);
    }
  };

  const handleAddNewOffer = async () => {
    const newOffer = offers[offers.length - 1];

    if (!newOffer.label || newOffer.sections.some((section) => !section)) {
      setSnackbarMessage({
        text: "Proszę wypełnić wszystkie pola.",
        severity: "warning",
      });
      setSnackbarOpen(true);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("label", newOffer.label);

      const existingImages = [];
      (newOffer.allImages || []).forEach((image) => {
        if (image.file) {
          formData.append("images", image.file);
        } else {
          existingImages.push(image.url.replace(`${endpoint.baseUrl}/`, ""));
        }
      });
      formData.append("existingImages", JSON.stringify(existingImages));

      newOffer.sections.forEach((section, index) => {
        formData.append(`sections[${index}]`, section);
      });

      const response = await axiosAuth.post(
        `${endpoint.baseUrl}/offerPages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const createdOffer = {
        ...response.data,
        allImages: [
          ...response.data.images.map((image) => ({
            url: `${endpoint.baseUrl}/${image.replace(/\\/g, "/")}`,
          })),
        ],
      };
      const updatedOffers = [
        ...offers.slice(0, -1),
        createdOffer,
        createEmptyOffer(),
      ];
      setOffers(updatedOffers);
      setHasChanged([...hasChanged.slice(0, -1), false, false]);
      setSnackbarMessage({
        text: "Oferta została dodana.",
        severity: "success",
      });
      setSnackbarOpen(true);
    } catch (error) {
      console.error("There was an error creating the offer: ", error);
      setSnackbarMessage({
        text: "Błąd podczas dodawania oferty.",
        severity: "error",
      });
      setSnackbarOpen(true);
    }
  };

  const updateHasChanged = (index, value) => {
    const newHasChanged = [...hasChanged];
    newHasChanged[index] = value;
    setHasChanged(newHasChanged);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (images.length > 0 && photoIndex !== null) {
      setIsOpen(true);
    }
  }, [images, photoIndex]);

  const handleOpenLightbox = (index) => {
    const allImages = offers.flatMap((offer) => [
      ...(offer.previewImages || []),
      ...(offer.images || []).map((image) =>
        typeof image === "string" && !image.startsWith("blob:")
          ? `${endpoint.baseUrl}/${image.replace(/\\/g, "/")}`
          : image
      ),
    ]);
    setImages(allImages);
    setPhotoIndex(index);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SpinnerDotted size={90} color="#a9ffff" />
      </div>
    );
  }
  return (
    <Container
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <InfoAdmin
        text="Oferta"
        imagePath="/images/AdminPage/OfferPage/image1.jpg"
        imageWidth={isXs || isSm ? "90%" : 600}
      />
      <Paper sx={{ padding: 4, width: isXs || isSm ? "100%" : 600 }}>
        {offers.map((offer, index) => (
          <React.Fragment key={index}>
            <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
              {offer.id ? `#${index + 1}` : "Nowa oferta"}
            </Typography>
            <Box sx={{ mb: 4, display: "flex", flexDirection: "column" }}>
              <Box
                className={classes.imageRow}
                sx={{
                  justifyContent: offer.allImages?.length === 0 ? "center" : "",
                }}
              >
                <label
                  htmlFor={`upload-photo-${index}`}
                  className={classes.uploadButton}
                >
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      border: "1px #000 solid",
                      borderRadius: "4px",
                      width: 180,
                      height: 180,
                    }}
                  >
                    <AddPhotoAlternateIcon
                      sx={{ fontSize: 70, color: "#000" }}
                    />
                  </IconButton>
                  <input
                    accept="image/*"
                    id={`upload-photo-${index}`}
                    type="file"
                    multiple
                    className={classes.inputFile}
                    onChange={(e) => handleImageChange(e, index)}
                  />
                </label>
                {offer.allImages?.map((image, imageIndex) => (
                  <Card className={classes.imageCard} key={imageIndex}>
                    <CardActionArea
                      sx={{ display: "flex" }}
                      onClick={() => handleOpenLightbox(imageIndex)}
                    >
                      <CardMedia
                        className={classes.media}
                        component="img"
                        image={
                          typeof image.url === "string"
                            ? image.url
                            : URL.createObjectURL(image.file)
                        }
                        title={`Offer ${index} - ${imageIndex + 1}`}
                      />
                    </CardActionArea>
                    <CardActions
                      disableSpacing
                      sx={{ position: "absolute", right: 0, top: 0 }}
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteImage(index, imageIndex)}
                        sx={{
                          backgroundColor: "#fff",
                          color: "#000",
                          padding: "2px",
                          transition: "transform 0.3s ease-in-out !important",
                          "&:hover": {
                            backgroundColor: "#fff !important",
                            color: "#000 !important",
                            transform: "scale(0.9)",
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </CardActions>
                    <CardActions
                      disableSpacing
                      style={{
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        gap: 20,
                      }}
                    >
                      <IconButton
                        aria-label="move left"
                        onClick={() => moveImage(index, imageIndex, -1)}
                        sx={{
                          backgroundColor: "#fff !important",
                          color: "#000",
                          padding: "2px",
                          transition: "transform 0.3s ease-in-out !important",
                          "&:hover": {
                            backgroundColor: "#fff !important",
                            color: "#000 !important",
                            transform: "scale(0.9)",
                          },
                        }}
                      >
                        <ArrowBackIosNewIcon sx={{ fontSize: 40 }} />
                      </IconButton>
                      <IconButton
                        aria-label="move right"
                        onClick={() => moveImage(index, imageIndex, 1)}
                        sx={{
                          backgroundColor: "#fff !important",
                          color: "#000",
                          padding: "2px",
                          transition: "transform 0.3s ease-in-out !important",
                          "&:hover": {
                            backgroundColor: "#fff !important",
                            color: "#000 !important",
                            transform: "scale(0.9)",
                          },
                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 40 }} />
                      </IconButton>
                    </CardActions>
                  </Card>
                ))}
              </Box>

              <TextField
                fullWidth
                label="Tytuł"
                variant="outlined"
                margin="normal"
                multiline
                value={offer.label}
                onChange={(e) => handleTextChange(e, index, "label")}
              />
              {offer.sections?.map((section, sectionIndex) => (
                <Box
                  key={sectionIndex}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    key={sectionIndex}
                    fullWidth
                    label={`Sekcja #${sectionIndex + 1}`}
                    variant="outlined"
                    margin="normal"
                    multiline
                    value={section}
                    onChange={(e) => handleTextChange(e, index, sectionIndex)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            sx={{
                              color: "#ad1f1f",
                              height: "min-content",
                              float: "right",
                            }}
                            onClick={() =>
                              handleDeleteSection(index, sectionIndex)
                            }
                            edge="end"
                          >
                            <BackspaceIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <IconButton
                    onClick={() => handleAddSection(index)}
                    color="primary"
                    sx={{ float: "right" }}
                  >
                    <AddIcon sx={{ fontSize: 30, padding: 0 }} />
                  </IconButton>
                </div>
                <div>
                  {offer.id && (
                    <>
                      <IconButton
                        onClick={() => handleSaveOffer(index)}
                        variant="contained"
                        sx={{
                          color: hasChanged[index] ? "#227722" : "#777",
                        }}
                        disabled={!hasChanged[index]}
                      >
                        <SaveIcon sx={{ fontSize: 30, padding: 0 }} />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteOffer(offer.id, index)}
                        variant="contained"
                        sx={{
                          color: "#ad1f1f",
                        }}
                      >
                        <DeleteForeverIcon sx={{ fontSize: 30, padding: 0 }} />
                      </IconButton>
                    </>
                  )}
                </div>
              </div>
              {offer.id && <Divider sx={{ my: 2 }} />}
            </Box>
          </React.Fragment>
        ))}
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleAddNewOffer}
          sx={{
            my: 2,
            color: "#fff",
            background: "linear-gradient(180deg, #121212,  #6d6d6de6 )",
            fontWeight: 800,
          }}
        >
          Dodaj nową ofertę
        </Button>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage.text}
        </Alert>
      </Snackbar>
      <ConfirmDelete
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirmDeleteOffer}
        title={`Czy na pewno chcesz usunąć ofertę #${selectedOfferIndex + 1}?`}
      />
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map((img) => ({ src: img }))}
          currentIndex={photoIndex}
          on={{
            clickNext: () => setPhotoIndex((photoIndex + 1) % images.length),
            clickPrev: () =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length),
          }}
        />
      )}
    </Container>
  );
};

export default OfferPageAdmin;
