import { Box, Grid, Typography, IconButton } from "@mui/material";
import useIsMobile from "../../Assets/useIsMobile";
import OfferPageStyles from "./OfferPageStyles";
import { endpoint } from "../../endpoint";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ImageGallery from "react-image-gallery";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useState, useEffect, useMemo } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export const TabPanel = (props) => {
  const { value, index, classes, tab, onImageLoad } = props;
  const styles = OfferPageStyles();
  const { isXs, isSm } = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [thumbnailsVisible, setThumbnailsVisible] = useState(false);

  const images = useMemo(() => {
    return tab.images
      ? tab.images.map((image) => ({
          original: `${endpoint.baseUrl}/${image.replace(/\\/g, "/")}`,
          thumbnail: `${endpoint.baseUrl}/${image.replace(/\\/g, "/")}`,
        }))
      : [];
  }, [tab.images]);

  const handleFullScreen = (index) => {
    setTimeout(() => {
      setPhotoIndex(index);
      setIsOpen(true);
    }, 200);
  };

  useEffect(() => {
    if (images.length === 0) {
      onImageLoad();
    }
  }, [images, onImageLoad]);

  const renderCustomItem = (item) => {
    const currentIndex = images.findIndex(
      (img) => img.original === item.original
    );
    return (
      <div
        style={{
          position: "relative !important",
          width: "fit-content !important",
        }}
      >
        <img
          src={item.original}
          alt={item.originalAlt}
          style={{
            width: "100% !important",
            height: "auto !important",
          }}
          onLoad={onImageLoad}
        />
        <IconButton
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
          }}
          onClick={() => handleFullScreen(currentIndex)}
        >
          <FullscreenIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <div hidden={value !== index}>
      {value === index && (
        <div>
          <Box
            sx={{
              padding: isXs ? "30px 10px" : "40px",
              mt: isXs || isSm ? 5 : 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#ffffffa8",
              borderRadius: 7,
              boxShadow: "0px 0px 5px 2px rgb(245 245 245 / 15%)",
              maxWidth: 900,
              color: "#fff",
              background: "linear-gradient(180deg, #03090ede, #8a8a8a21)",
            }}
          >
            {tab.sections[0] && (
              <Typography
                className={`${classes.tabPanel} ${styles.firstSection}`}
                paragraph
              >
                {tab.sections[0]}
              </Typography>
            )}
            {images.length > 0 ? (
              <div style={{ position: "relative", textAlign: "center" }}>
                <ImageGallery
                  items={images}
                  showThumbnails={thumbnailsVisible && images.length > 1}
                  showFullscreenButton={false}
                  useBrowserFullscreen={false}
                  additionalClass={
                    isXs || isSm
                      ? styles.imageGalleryMobile
                      : styles.imageGallery
                  }
                  renderItem={(item) => renderCustomItem(item)}
                  autoPlay={true}
                  slideInterval={6000}
                />
                {isOpen && (
                  <Lightbox
                    open={isOpen}
                    slides={images.map((img) => ({ src: img.original }))}
                    index={photoIndex}
                    close={() => setIsOpen(false)}
                    next={() => setPhotoIndex((photoIndex + 1) % images.length)}
                    prev={() =>
                      setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                      )
                    }
                  />
                )}
                {images.length > 1 && (
                  <IconButton
                    onClick={() => setThumbnailsVisible((prev) => !prev)}
                    style={{
                      marginTop: 10,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "#fff",
                    }}
                  >
                    {thumbnailsVisible ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                )}
              </div>
            ) : (
              tab.image && (
                <img
                  src={`${endpoint.baseUrl}/${tab.image}`}
                  alt=""
                  style={{
                    width: isXs || isSm ? 290 : 400,
                    height: "auto",
                    marginBottom: "20px",
                    borderRadius: 30,
                  }}
                />
              )
            )}
            <Grid className={styles.bottomSections}>
              {tab.sections.slice(1).map((sectionContent, i) => (
                <Typography key={i} className={styles.otherSections} paragraph>
                  <RadioButtonCheckedIcon />
                  {sectionContent}
                </Typography>
              ))}
            </Grid>
          </Box>
        </div>
      )}
    </div>
  );
};
